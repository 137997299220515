/** PRODUCT **/

.frontpage-grid__product {
  display: flex;
  margin: 0 7.5px 7.5px;
  flex: 0 0 47.5%;
  flex-basis: calc(50% - 15px);
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  background: $white;
  color: $gray-900;
  box-shadow: 0 6px 11px rgba(36, 37, 38, 0.04);
  border: 1px solid $gray-200;
  text-align: left;
  transition: transform 150ms ease-out, box-shadow 150ms ease-out;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.20);
    transition: opacity 200ms ease;
  }

  &:hover {
    background: $white;
    transform: translateY(-2px);
    box-shadow: 0 11px 16px rgba(36, 37, 38, 0.05);

    &::after {
      opacity: 1;
    }
  }

  @include media-breakpoint-up(md) {
    flex: 0 0 31%;
    flex-basis: calc(33.33% - 15px);
  }

  @include media-breakpoint-up(lg) {
    flex: 0 0 23.5%;
    flex-basis: calc(25% - 15px);
  }

  @include media-breakpoint-up(xl) {
    flex: 0 0 19%;
    flex-basis: calc(20% - 15px);
  }
}

.frontpage-grid__product-link {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  align-content: center;
  justify-content: center;
  position: relative;
  min-height: 140px;
  color: $gray-900;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: $gray-900;
    text-decoration: none;
  }

  @include media-breakpoint-up(sm) {
    min-height: 340px;
  }
}

.frontpage-grid__product-ribbon {
  position: absolute;
  top: 0;
  width: 100%;
  color: $white;
  background: $gray-900;
  padding: 3px 15px;
  // Allowing 3px radius to match what 4px looks like on the bigger tile
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  text-transform: uppercase;
  font-size: 1.2rem;


  @include media-breakpoint-up(sm) {
    top: 20px;
    left: 20px;
    width: auto;
    z-index: 10;
    border: 1px solid $gray-900;
    padding: 0.5em 1.25em 0.45em;
    border-radius: 2px;
    font-size: 1.4rem;
  }

  &.product-ribbon--sale {
    border-color: $red-500;
    background: $red-500;
  }

  &.product-ribbon--addon {
    border-color: $blue-400;
    background: $blue-400;
  }
}

.product__image-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 200px;

  @include media-breakpoint-up(sm) {
    height: 350px;
  }
}

.product__image {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 30px 0;
}

// Product text content
.product__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 15px;
  border-top: 1px solid $gray-200;

  @include media-breakpoint-up(sm) {
    padding: 20px 25px;
  }

  ul {
    // hides functionality for mobile tiles
    display: none;
    margin: 0 0 10px;
    padding: 0 0 0 20px;

    @include media-breakpoint-up(sm) {
      display: block;
    }

    // Base Styling for WYSIWYG field
    li {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    // hides functionality for mobile tiles
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }

    & + p {
      margin: 0 0 10px;
    }
  }
}

.product__title {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0.25em;
  font-size: 1.6rem;

  @include media-breakpoint-up (sm) {
    margin-bottom: 0.5em;
    font-size: 1.8rem;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.extra-title {
  display: inline-block;
  padding-top: 0.3em;
  font-weight: 400;
}

// Prices
.product__prices {
  display: block;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 5px;

  @include media-breakpoint-up(sm) {
    font-size: 1.6rem;
    margin: 0 0 10px;
  }

  .vanadium {
    color: #a35af9;
  }
}

.product__prices-price {
  &.tile-price--is-promo {
    display: block;

    @include media-breakpoint-up(sm) {
      display: inline-block;
    }

    &.is-promo--discount {
      color: $red-500;

      span {

        &:last-child {
          margin-left: 0;
        }
      }
    }

    &.is-promo--addon {
      color: $blue-400;
    }

    span {
      font-size: 70%;

      &:first-child {
        font-weight: 900;
      }
    }
  }
}

.product__prices-price--discount {
  display: inline-block;
  color: $gray-900;
  margin-top: -19px;
  text-decoration: line-through;

  span {
    font-size: 70%;
    text-decoration: line-through;
  }
}

// Color Variants/Swatches
.product__colors-wrapper {
  // hides functionality for mobile tiles
  display: none;

  @include media-breakpoint-up(sm) {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.product__colors-list {
  text-align: center;
  padding: 0;
  margin-bottom: 3px;
  height: 20px;
}

.product__color {
  display: inline-block;
  height: 15px;
  width: 15px;
  list-style: none;

  & + & {
    margin-left: 2px;
  }
}

.colors-list__item-box {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 50px;

  &.product-tile__options-item-color--white {
    border-color: $gray-900;
  }
}
