/** ROW **/

.frontpage-grid__row {
  padding-left: $frontpage-grid-gutter * 0.5;
  padding-right: $frontpage-grid-gutter * 0.5;
}

.frontpage-grid__row-inner {
  @include frontpage-grid-row();

  margin-left: $frontpage-grid-gutter * -0.5;
  margin-right: $frontpage-grid-gutter * -0.5;
}

.frontpage-grid__row--full-width {
  position: relative;
  left: 0;
  right: 0;
  margin-top: $frontpage-grid-gutter * 0.5;
  margin-bottom: $frontpage-grid-gutter * 0.5;
  margin-left: -($frontpage-grid-gutter * 0.5);
  margin-right: -($frontpage-grid-gutter * 0.5);

  .frontpage-grid__row-inner {
    //@include grid-container-breakpoints();
    margin-right: auto;
    margin-left: auto;

    padding-right: $frontpage-grid-gutter * 0.5;
    padding-left: $frontpage-grid-gutter * 0.5;
  }
}
