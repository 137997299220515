/** PRODUCT GRID **/

.frontpage-grid__product-grid-header {
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;

  h2 {
    text-transform: uppercase;
    font-weight: 900;
    margin: 0;
  }

  p {
    margin-top: 0.8em;
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
  }
}

// Grid Component
.frontpage-grid__product-grid {
  padding-top: 20px;

  .container--flex {
    align-content: center;
    justify-content: center;

    // Fix for ?edit mode.
    .country-container {
      display: flex;
      margin: 0 7.5px 7.5px;
      flex: 0 0 47.5%;
      flex-basis: calc(50% - 15px);

      @include media-breakpoint-up(md) {
        flex: 0 0 31%;
        flex-basis: calc(33.33% - 15px);
      }

      @include media-breakpoint-up(lg) {
        flex: 0 0 23.5%;
        flex-basis: calc(25% - 15px);
      }

      @include media-breakpoint-up(xl) {
        flex: 0 0 19%;
        flex-basis: calc(20% - 15px);
      }

      .prod-tile {
        flex-grow: 1;
        margin: 0 auto;
      }
    }
  }

  .row--flex {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin: 0;

    @include media-breakpoint-up(sm) {
      margin: 0 -10px;
    }
  }
}
