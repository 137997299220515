/** GRID **/

.frontpage-grid {
  @include grid-container();
  //@include grid-container-breakpoints();

  padding-right: $frontpage-grid-gutter * 0.5;
  padding-left: $frontpage-grid-gutter * 0.5;

  margin-top: $frontpage-grid-gutter * 0.5;
  margin-bottom: $frontpage-grid-gutter * 0.5;
}
