/** COLUMN **/

.frontpage-grid__column {
  @include frontpage-grid-column-base();

  @include media-breakpoint-up(sm) {
    flex: 1 1 0;
    max-width: 100%;
  }

  padding-left: $frontpage-grid-gutter * 0.5;
  padding-right: $frontpage-grid-gutter * 0.5;

  display: flex;
}

// Columns

@include media-breakpoint-up(sm) {
  .frontpage-grid__column--3 {
    @include grid-column-size(3);
  }
  .frontpage-grid__column--4 {
    @include grid-column-size(4);
  }
  .frontpage-grid__column--6 {
    @include grid-column-size(6);
  }
  .frontpage-grid__column--8 {
    @include grid-column-size(8);
  }
  .frontpage-grid__column--9 {
    @include grid-column-size(9);
  }
  .frontpage-grid__column--12 {
    @include grid-column-size(12);
  }
}

// Height

.frontpage-grid__column--auto {
  min-height: 0;
}
.frontpage-grid__column--short {
  min-height: $frontpage-grid-size-short-sm;
}
.frontpage-grid__column--medium {
  min-height: $frontpage-grid-size-medium-sm;
}
.frontpage-grid__column--tall {
  min-height: $frontpage-grid-size-tall-sm;
}

@include media-breakpoint-up(sm) {
  .frontpage-grid__column--auto {
    min-height: 0;
  }
  .frontpage-grid__column--short {
    min-height: $frontpage-grid-size-short;
  }
  .frontpage-grid__column--medium {
    min-height: $frontpage-grid-size-medium;
  }
  .frontpage-grid__column--tall {
    min-height: $frontpage-grid-size-tall;
  }
}

// removes min-height when content is outside img
.frontpage-grid__column--left,
.frontpage-grid__column--right {
  &.frontpage-grid__column--auto {
    min-height: auto;

    .frontpage-grid__tile-bg {
      min-height: 0;

      @include media-breakpoint-up(sm) {
        min-height: 0;
      }
    }
  }
  &.frontpage-grid__column--short {
    min-height: auto;

    .frontpage-grid__tile-bg {
      min-height: $frontpage-grid-size-short;
    }
  }
  &.frontpage-grid__column--medium {
    min-height: auto;

    .frontpage-grid__tile-bg {
      min-height: 350px;

      @include media-breakpoint-up(sm) {
        min-height: $frontpage-grid-size-medium;
      }
    }
  }
  &.frontpage-grid__column--tall {
    min-height: auto;

    .frontpage-grid__tile-bg {
      min-height: 450px;

      @include media-breakpoint-up(sm) {
        min-height: $frontpage-grid-size-tall;
      }
    }
  }
}

.frontpage-grid__column--above,
.frontpage-grid__column--below {
  &.frontpage-grid__column--auto {
    min-height: auto;

    .frontpage-grid__tile-bg {
      min-height: 0;

      @include media-breakpoint-up(sm) {
        min-height: 0;
      }
    }
  }
  &.frontpage-grid__column--short {
    min-height: auto;

    .frontpage-grid__tile-bg {
      min-height: $frontpage-grid-size-short;
    }
  }
  &.frontpage-grid__column--medium {
    min-height: auto;

    .frontpage-grid__tile-bg {
      min-height: 350px;

      @include media-breakpoint-up(sm) {
        min-height: $frontpage-grid-size-medium-sm;
      }
    }
  }
  &.frontpage-grid__column--tall {
    min-height: auto;

    .frontpage-grid__tile-bg {
      min-height: 450px;

      @include media-breakpoint-up(sm) {
        min-height: $frontpage-grid-size-tall-sm;
      }
    }
  }
}
