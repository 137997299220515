@import "variables";
@import "mixins/breakpoints";
@import "mixins/grid";
@import "mixins/typography";
@import "mixins/button";


// Variables

$frontpage-grid-size-short-sm: 250px;
$frontpage-grid-size-medium-sm: 450px;
$frontpage-grid-size-tall-sm: 650px;

$frontpage-grid-size-short: 300px;
$frontpage-grid-size-medium: 600px;
$frontpage-grid-size-tall: 900px;

$frontpage-grid-gutter: 15px;


// Implementation

@import "grid";
@import "category-grid";
@import "row";
@import "column";
@import "tile";
@import "edit";
@import "product-grid";
@import "product";
@import "benefit-banner";
@import "blog-list";
@import "blog-post";
