/** CATEGORY GRID **/

// container
.frontpage-grid__category-grid {
  padding-top: 20px;
  padding-left: $frontpage-grid-gutter * 0.5;
  padding-bottom: 20px;
  padding-right: $frontpage-grid-gutter * 0.5;

  h2 {
    margin: 0 auto 30px;
  }
}

.frontpage-grid__category-inner {

  &.glide--slider {
    padding-bottom: 20px;
  }

  .glide__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50px;
    background-color: $gray-900;
    border: none;
    color: $white;
    z-index: 99;

    &:hover {
      background-color: $gray-800;
    }

    &.glide__arrow--right {
      right: -10px;
    }

    &.glide__arrow--left {
      left: -10px;
    }

    &.glide__arrow--disabled {
      cursor: default;
      background-color: $gray-200;
      color: $gray-600;
      opacity: 0.8;
    }

    svg {
      width: auto;
    }
  }

  .glide__bullets {
    bottom: -22px;
  }

  .glide__bullet {
    background-color: $gray-400;

    &:focus {
      background-color: $purple-500;
      border-color: $purple-500;
    }

    &:hover {
      background-color: $gray-600;
      border-color: $gray-600;
    }
  }

  .glide__bullet--active {
    background-color: $gray-800;

    &:focus {
      background-color: $gray-800;
    }
  }
}

// product category
.frontpage-grid__product-category {
  display: flex;
  flex-grow: 1;

  @include media-breakpoint-up(md) {
    flex-grow: 0;
  }

  a {
    color: $gray-900;
    text-decoration: none;
    transition: color 100ms ease-in-out;

    &:hover {
      text-decoration: underline;
    }
  }
}

.frontpage-grid__product-category-link {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 2.4rem;
  }

  &:hover {
    img {
      transform: translateY(-2px);
    }
  }

  img {
    display: block;
    max-width: 150px;
    transform: translateY(2px);
    transition: transform 100ms ease-in-out;

    @include media-breakpoint-up(sm) {
      max-width: 150px;
    }

    @include media-breakpoint-up(md) {
      max-width: 200px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 220px;
    }
  }
}
