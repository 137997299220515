/** EDIT MODE **/

.frontpage-grid--edit {
  .frontpage-grid__row-inner {
    position: relative;
  }

  .frontpage-grid__column {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px dashed $gray-400;
      content: " ";
      pointer-events: none;
    }
  }
}

.frontpage-grid__edit {
  position: absolute;
  bottom: 1px;
  background: $gray-200;
  color: $gray-600;
  font-size: 14px;
  padding: 2px 5px;
  border: 1px dashed $gray-200;
  border-left: 0;
  border-top: 0;
  text-shadow: 0 0 1px rgba(255, 255, 255, .75);
  z-index: 10;

  svg {
    width: 14px;
    vertical-align: middle;
  }
}

.frontpage-grid__row-inner > .frontpage-grid__edit {
  right: 1px;
}

.frontpage-grid__column > .frontpage-grid__edit {
  left: 1px;
}
