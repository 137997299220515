/** TILE **/

.frontpage-grid__tile {
  flex: 1;
  position: relative;

  margin-top: $frontpage-grid-gutter * 0.5;
  margin-bottom: $frontpage-grid-gutter * 0.5;

  overflow: hidden;
  padding: 20px 20px;
  border-radius: 2px;
  color: $white;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $white;
  }

  &:focus {
    outline-offset: 5px;
  }
}

.frontpage-grid__row--full-width .frontpage-grid__tile {
  // full width rows take care of their own margin
  margin-top: 0;
  margin-bottom: 0;
}

// Mobile readability fix

.frontpage-grid__tile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  z-index: 2;
}

// Content

.frontpage-grid__tile-content {
  max-width: 100%;
  z-index: 3;
}

.frontpage-grid__tile-header {
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  line-height: 0.9;
}

.frontpage-grid__tile-body {
  // i'm sorry mr jackson
  // uhhh, i am for reeeal
  // never meant to make your font-weight high
  // i apologize a million times
  font-weight: 600;
  margin: 0.1em 0 0;
}

.frontpage-grid__tile-subtext {
  margin: 0.8em 0 0;

  @include media-breakpoint-up(sm) {
    margin: 1.2em 0 0;
  }

  strong {
    font-weight: bold;
  }
}

// Content size
// --width-X classes are "width groups", so that:
//   1 = between 1 and 4 columns
//   2 = between 5 and 8 columns
//   3 = between 9 and 12 columns

.frontpage-grid__tile-content--width-full {
  max-width: 100%;
}

.frontpage-grid__tile-content--width-1 {
  @include media-breakpoint-up(sm) {
    max-width: 100%;
  }
  @include media-breakpoint-up(lg) {
    max-width: 80%;
  }
  @include media-breakpoint-up(xl) {
    max-width: 66%;
  }
}

.frontpage-grid__tile-content--width-2 {
  @include media-breakpoint-up(sm) {
    max-width: 80%;
  }
  @include media-breakpoint-up(lg) {
    max-width: 50%;
  }
}

.frontpage-grid__tile-content--width-3 {
  @include media-breakpoint-up(sm) {
    max-width: 50%;
  }
  @include media-breakpoint-up(lg) {
    max-width: 33%;
  }
}

// Alignment

.frontpage-grid__tile {
  &[data-align^="top-"] {
    align-items: flex-start;
  }
  &[data-align^="middle-"] {
    align-items: center;
  }
  &[data-align^="bottom-"] {
    align-items: flex-end;
    .frontpage-grid__tile-content {
      padding-bottom: 20px;
    }
  }

  &[data-align$="-left"] {
    justify-content: flex-start;
  }
  &[data-align$="-center"] {
    justify-content: center;
    .frontpage-grid__tile-content {
      text-align: center;
    }
  }
  &[data-align$="-right"] {
    justify-content: flex-end;
  }
}

// Background

.frontpage-grid__tile-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  &[data-align^="top-"] {
    background-position-y: top;
  }
  &[data-align^="middle-"] {
    background-position-y: center;
  }
  &[data-align^="bottom-"] {
    background-position-y: bottom;
  }

  &[data-align$="-left"] {
    background-position-x: left;
  }
  &[data-align$="-center"] {
    background-position-x: center;
  }
  &[data-align$="-right"] {
    background-position-x: right;
  }
}

// Small print

.frontpage-grid__small-print {
  position: absolute;
  bottom: ($frontpage-grid-gutter * 0.5) + 5px;
  width: 100%;
  color: $white;
  opacity: 0.6;
  text-align: center;

  p {
    margin: 0;
    font-size: 1.4rem;
  }
  a {
    color: $white;
    &:hover {
      text-decoration: none;
    }
  }
}

// Themes

@mixin frontpage-grid-theme($color, $button-color, $background) {
  background: $background;
  color: $color;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $color;
  }

  &[data-align^="top-left"] {
    .frontpage-grid__tile-overlay {
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0) 50%,
        rgba($background, 0.5) 100%
      );

      @include media-breakpoint-up(sm) {
        background-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 0) 50%,
          rgba($background, 0.5) 100%
        );
      }
    }
  }

  &[data-align^="top-right"] {
    .frontpage-grid__tile-overlay {
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0) 50%,
        rgba($background, 0.5) 100%
      );

      @include media-breakpoint-up(sm) {
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0) 50%,
          rgba($background, 0.5) 100%
        );
      }
    }
  }

  &[data-align^="bottom-left"] {
    .frontpage-grid__tile-overlay {
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 50%,
        rgba($background, 0.5) 100%
      );

      @include media-breakpoint-up(sm) {
        background-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 0) 50%,
          rgba($background, 0.5) 100%
        );
      }
    }
  }

  &[data-align^="bottom-right"] {
    .frontpage-grid__tile-overlay {
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 50%,
        rgba($background, 0.5) 100%
      );

      @include media-breakpoint-up(sm) {
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0) 50%,
          rgba($background, 0.5) 100%
        );
      }
    }
  }

  &.frontpage-grid__tile--has-bg {
    .frontpage-grid__tile-header,
    .frontpage-grid__tile-body {
      text-shadow: 0px 0px 8px rgba($background, 0.1);
    }
  }

  & + .frontpage-grid__small-print {
    color: $color;
    a {
      color: $color;
    }
  }

  .button {
    @if $button-color == white {
      @include button-white();
    } @else if $button-color == orange {
      @include button-orange();
    } @else {
      // default
    }

    &.button--orange {
      @include button-orange();
    }

    &.button--black {
      @include button-black();
    }

    &.button--white {
      @include button-white();
    }
  }
}

@mixin frontpage-grid-theme-underline($underline-color) {
  .frontpage-grid__tile-header {
    span {
      border-bottom: 0.18em solid $underline-color;
    }

    & + .frontpage-grid__tile-body {
      // add a bit of spacing to potential body
      // to account for the underline
      margin-top: 0.5em;
    }
  }
}

.frontpage-grid__tile--theme-dark {
  @include frontpage-grid-theme($white, white, $black);
}
.frontpage-grid__tile--theme-light {
  @include frontpage-grid-theme($black, black, $gray-100);
}
.frontpage-grid__tile--theme-dark-brand-underline {
  @include frontpage-grid-theme($white, white, $black);
  @include frontpage-grid-theme-underline($orange-500);

  .frontpage-grid__tile-header {
    line-height: 1.3;
  }
}
.frontpage-grid__tile--theme-light-brand-underline {
  @include frontpage-grid-theme($black, black, $gray-100);
  @include frontpage-grid-theme-underline($orange-500);

  .frontpage-grid__tile-header {
    line-height: 1.3;
  }
}

.frontpage-grid__tile--content-placement-left,
.frontpage-grid__tile--content-placement-right,
.frontpage-grid__tile--content-placement-above,
.frontpage-grid__tile--content-placement-below {
  &.frontpage-grid__tile {
    padding: 0;

    .frontpage-grid__tile-overlay {
      display: none;
    }
  }

  .frontpage-grid__tile-bg {
    position: static;
  }

  .frontpage-grid__tile-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;

    @include media-breakpoint-up(md) {
      padding: 20px;
    }
  }
}

.frontpage-grid__tile--content-placement-left,
.frontpage-grid__tile--content-placement-right {
  &.frontpage-grid__tile {
    &[data-align$="-right"] {
      .frontpage-grid__tile-content {
        text-align: right;
      }
    }
  }
}

.frontpage-grid__tile--content-placement-above,
.frontpage-grid__tile--content-placement-below {
  &.frontpage-grid__tile {
    &[data-align$="-right"] {
      .frontpage-grid__tile-content {
        text-align: right;
      }
    }
  }

  .frontpage-grid__tile-bg {
    flex: 0 0 60%;
  }
}

.frontpage-grid__tile--content-placement-left {
  &.frontpage-grid__tile {
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row-reverse;
    }
  }
  .frontpage-grid__tile-content {
    padding: 20px;
  }
}
.frontpage-grid__tile--content-placement-right {
  &.frontpage-grid__tile {
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }
  .frontpage-grid__tile-content {
    padding: 20px;
  }
}
.frontpage-grid__tile--content-placement-above {
  &.frontpage-grid__tile {
    flex-direction: column-reverse;
  }
  .frontpage-grid__tile-content {
    width: 100%;
    max-width: none;
  }
}
.frontpage-grid__tile--content-placement-below {
  &.frontpage-grid__tile {
    flex-direction: column;
  }
  .frontpage-grid__tile-content {
    width: 100%;
    max-width: none;
  }
}
